.l-kv {
  position: relative;
  background: $bgColor;
  overflow: hidden;
  @media #{$breakpoint} {
    padding-top: 8rem;
  }
  &_ttl {
    max-width: 124rem;
    margin: 0 auto;
    font-size: 9rem;
    font-family: $fontDM;
    color: #fff;
    line-height: 0.7;
    @media #{$breakpoint} {
      font-size: 4.3rem;
    }
    &_wrapper {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      padding: 0 6rem;
      transform: translateY(-8.5rem);
      z-index: 999;
      @media #{$breakpoint} {
        padding: 0 2rem;
        transform: translateY(-1.5rem);
      }
    }
  }
  &_slider {
    transform: translateX(10rem);
    opacity: 0;
  }
  .slick-slide {
    height: auto;
  }
  .slick-dots {
    position: absolute;
    right: 6rem;
    bottom: 4rem;
    display: flex;
    justify-content: space-between;
    font-size: 0;
    @media #{$breakpoint} {
      right: 2rem;
      bottom: 2rem;
    }
    .slick-active {
      button {
        background: #fff;
      }
    }
    li {
      margin-left: 1rem;
      &:first-of-type {
        margin-left: 0;
      }
    }
    button {
      width: 0.5rem;
      height: 0.5rem;
      border: 1px solid #fff;
      transition: background $kitekiTransition;
    }
  }
}
.l-about {
  padding: 12rem 0;
  @media #{$breakpoint} {
    padding: 10rem 0;
  }
  &_wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    @media #{$breakpoint} {
      display: block;
    }
  }
  &_content {
    width: 62rem;
    padding-left: 12rem;
    @media #{$breakpoint} {
      width: 100%;
      padding-left: 0;
    }
    &_ttl {
      margin-bottom: 4rem;
      @media #{$breakpoint} {
        margin-bottom: 4rem;
      }
    }
    &_txt {
      margin-bottom: 4.5rem;
      font-size: 1.8rem;
      line-height: 2;
      @media #{$breakpoint} {
        font-size: 1.6rem;
      }
    }
    &_item {
      &_ttl {
        margin-bottom: 1rem;
        font-size: 3.2rem;
        font-weight: 700;
        @media #{$breakpoint} {
          font-size: 2.3rem;
        }
      }
      &_txt {
        font-size: 1.8rem;
        line-height: 2;
        @media #{$breakpoint} {
          margin-bottom: 3.5rem;
          font-size: 1.6rem;
        }
      }
    }
  }
  &_img {
    &_wrapper {
      width: calc(100% - 62rem);
      @media #{$breakpoint} {
        width: 100%;
      }
    }
  }
}
.l-menu {
  padding: 7.2rem 0 12.2rem;
  background: url('/img/top/l-menu_bg.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  @media #{$breakpoint} {
    padding: 10rem 0;
  }
  &_ttl {
    font-size: 25rem;
    font-family: $fontDM;
    text-align: center;
    color: #fff;
    @media #{$breakpoint} {
      font-size: 11.2rem;
    }
  }
  &_item {
    $this: &;
    width: 50%;
    margin-bottom: 6rem;
    @media #{$breakpoint} {
      width: 100%;
      margin-bottom: 5rem;
    }
    &:nth-of-type(n + 2) {
      margin-bottom: 0rem;
      @media #{$breakpoint} {
        margin-bottom: 5rem;
      }
    }
    &:last-of-type {
      @media #{$breakpoint} {
        margin-bottom: 0;
      }
    }
    &_wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: -5.5rem 0 6rem;
      @media #{$breakpoint} {
        margin: -2.5rem 0 5rem;
      }
    }
    &_link {
      &:hover {
        #{$this}_img {
          transform: scale(1.05);
          opacity: .6;
          @media #{$breakpoint} {
            transform: scale(1);
            opacity: 1;
          }
        }
        #{$this}_head {
          color: $keyColor;
          @media #{$breakpoint} {
            color: #fff;
          }
        }
      }
    }
    &_img {
      transition: transform $kitekiTransition, opacity $kitekiTransition;
      &_wrapper {
        background: #000;
        margin-bottom: 3rem;
        overflow: hidden;
      }
    }
    &_head {
      font-size: 6rem;
      font-family: $fontDM;
      text-align: center;
      color: #fff;
      transition: color $kitekiTransition;
      @media #{$breakpoint} {
        font-size: 4.4rem;
      }
      &_sub {
        display: block;
        font-size: 1.6rem;
        letter-spacing: 0.06em;
        @media #{$breakpoint} {
          font-size: 1.3rem;
        }
      }
    }
  }
  &_btn {
    display: block;
    width: 36rem;
    height: 7rem;
    background: $keyColor;
    border: 1px solid $keyColor;
    margin: 0 auto;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 6.8rem;
    text-align: center;
    transition: color $kitekiTransition, background $kitekiTransition;
    @media #{$breakpoint} {
      width: 100%;
      height: 6rem;
      line-height: 5.8rem;
    }
    &:hover {
      background: #fff;
      color: $keyColor;
      @media #{$breakpoint} {
        background: $keyColor;
        color: #fff;
      }
    }
  }
}
.l-reservation {
  &_btn {
    display: block;
    width: 57rem;
    height: 11.5rem;
    margin: 0 auto;
    background: #fff;
    border: 1px solid $keyColor;
    line-height: 11.3rem;
    color: $keyColor;
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
    transition: color $kitekiTransition, background $kitekiTransition;
    @media #{$breakpoint} {
      width: 100%;
      height: 8rem;
      line-height: 7.8rem;
      font-size: 2.4rem;
    }
    &:hover {
      background: #000;
      color: $keyColor;
      @media #{$breakpoint} {
        background: #fff;
        color: $keyColor;
      }
    }
    &_wrapper {
      padding: 6rem 0;
      background: $keyColor;
      @media #{$breakpoint} {
        padding: 4rem 2rem;
      }
    }
  }
}
